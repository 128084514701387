
import Vue from "vue"
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import SinnoIcons from "@/components/SinnoIcons.vue"

@Component({
    components: {
        SinnoIcons: SinnoIcons
    }
})
export default class MainCard extends Vue {
    @Prop({required: true})
    public readonly cardTitle!: string;

    @Prop({required: false})
    public readonly cardTitleIcon!: string;

    public iconSize = 50;

    public get title(): string {
        return this.cardTitle
    }

    public get icon(): string {
        return this.cardTitleIcon
    }
}
