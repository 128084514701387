
import Vue from "vue"
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import GearIcon from "@/components/icons/GearIcon.vue"
import HomeIcon from "@/components/icons/HomeIcon.vue"

@Component({
    components: {
        GearIcon: GearIcon,
        HomeIcon: HomeIcon
    }
})
export default class SinnoIcons extends Vue {
    @Prop({required: true})
    public readonly iconName!: string;

    @Prop({required: true})
    public readonly iconSize!: number;

    @Prop({required: true})
    public readonly iconColor!: string;

    public get icon(): string {
        return this.iconName;
    }

    public get size(): number {
        return this.iconSize;
    }

    public get color(): any {
        if (this.iconName.substring(0, 6) == 'sinno-') {
            if (this.iconColor == 'primary') return this.$vuetify.theme.themes.light.primaryContrast;
            else return this.$vuetify.theme.themes.light.secondaryContrast;
        }
        else return this.iconColor;
    }
}
