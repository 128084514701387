
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import SinnoIcons from "@/components/SinnoIcons.vue"

@Component({
    components: {
        SinnoIcons: SinnoIcons
    }
})
export default class ModuleCard extends Vue {
    @Prop({required: true})
    readonly color!: string;

    @Prop({required: true})
    public moduleText!: string;

    @Prop({required: true})
    public moduleIcon!: string;

    public iconSize = 80;

    public getTextColor() : string {
      return this.color + 'Contrast--text';
    }

    public getColor(): string {
      return this.color;
    }
}
