
import Vue from 'vue'
import ModuleCard from '@/components/ModuleCard.vue'
import Component from 'vue-class-component'
import MainCard from '@/components/MainCard.vue'

@Component({
  components: {
    ModuleCard: ModuleCard,
    MainCard: MainCard
  }
})

export default class HomeView extends Vue {
  public modules: Module[] = [
    {
      name: this.$vuetify.lang.t(`$vuetify.menu_companyLink`),
      icon: 'sinno-gear',
      linkTo: 'Company'
    },
    {
      name: this.$vuetify.lang.t(`$vuetify.menu_branchLink`),
      icon: 'sinno-gear',
      linkTo: 'Branches'
    },
    {
      name: this.$vuetify.lang.t(`$vuetify.menu_moduleSettingsLink`),
      icon: 'sinno-gear',
      linkTo: 'ModuleSettings'
    }
  ]

  get showModules() : Module[] {
    return this.modules
  }

  public getColorByIndex(index: number) : string {
    if (index == 0 || index %3 === 0) return 'primary'
    else return 'secondary'
  }
}

interface Module {
  name: string;
  icon: string;
  linkTo: string;
}
